<template>
    <v-app>
        <router-view v-slot="{ Component }">
            <transition v-if="isTransitionPage" name="slide" mode="out-in">
                <component :is="Component" />
            </transition>
            <component v-else :is="Component" />
        </router-view>
        <DialogWarning :dialog="showDialog" @update:dialog="setShowDialog" />
        <v-snackbar v-model="showSnackbar" :timeout="10000">
            {{ snackbarMessage }}
        </v-snackbar>
    </v-app>
</template>

<script>
import { onMounted, onBeforeUnmount } from 'vue';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { mapState, mapMutations, mapActions } from 'vuex';
import DialogWarning from './components/connected-components/Dialog-Warning.vue';

export default {
    components: {
        DialogWarning,
    },
    data() {
        return {
            isOnline: navigator.onLine, // Vérifie si l'utilisateur est initialement en ligne
            showSnackbar: false,
            snackbarMessage: '',
        };
    },
    computed: {
        isTransitionPage() {
            // Vérifiez si la route active est "book-chapters" ou "book-chapters-specific"
            return (
                this.$route.name === 'book-chapters' || this.$route.name === 'book-chapters-specific'
            );
        },
        ...mapState(['showDialog']),
    },
    methods: {
        ...mapActions(['loadUserPhoto']), // Action pour charger la photo utilisateur
        ...mapMutations(['setShowDialog']),
        handleOnline() {
            this.isOnline = true;
            this.snackbarMessage = 'Ou anliy!';
            this.showSnackbar = true;
        },
        handleOffline() {
            this.isOnline = false;
            this.snackbarMessage =
                'Koneksyon entènèt ou tonbe. Ou ap fonksyone san entènet. Sa rann aplikasyon an lan, men ou kapab kontinye li liv ou menm san entènet.';
            this.showSnackbar = true;
        },
        async configureStatusBar() {
            if (Capacitor.isNativePlatform()) {
                // Configuration de la status bar uniquement sur les plateformes natives
                await StatusBar.setOverlaysWebView({ overlay: false });
                await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
                await StatusBar.setStyle({ style: 'LIGHT' });
                await StatusBar.show();
            }
        },
    },
    setup() {
        // Utilisation de `onMounted` pour configurer la StatusBar au montage
        onMounted( () => {
            // await StatusBar.setOverlaysWebView({ overlay: false });
            // await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
            // await StatusBar.setStyle({ style: 'LIGHT' });
            // await StatusBar.show();
            if (Capacitor.isNativePlatform()) {
                setTimeout(async () => {
                    await StatusBar.setOverlaysWebView({ overlay: false });
                    await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
                    await StatusBar.setStyle({ style: 'LIGHT' });
                }, 100); // Délai pour s'assurer que le DOM est prêt
            }
        });

        // Optionnel : Ajout d'un hook avant démontage
        onBeforeUnmount(async () => {
            if (Capacitor.isNativePlatform()) {
                await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
                await StatusBar.setStyle({ style: 'LIGHT' });
            }
        });
    },
    async mounted() {
        await this.loadUserPhoto(); // Charger la photo utilisateur dès que l'application est montée
        // Écoute les événements réseau
        window.addEventListener('online', this.handleOnline);
        window.addEventListener('offline', this.handleOffline);

        // Configuration de la StatusBar (redondant, mais parfois utile pour assurer la stabilité)
        await this.configureStatusBar();
    },
    beforeUnmount() {
        // Nettoie les écouteurs pour éviter les fuites mémoire
        window.removeEventListener('online', this.handleOnline);
        window.removeEventListener('offline', this.handleOffline);
    },
};
</script>

<style>

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.6s ease;
}

.slide-enter {
    transform: translateX(100%);
}

.slide-leave-to {
    transform: translateX(-100%);
}
</style>

<!-- <template>
    <v-app class="bottom-safe-area">
        <router-view v-slot="{ Component }">
            <transition v-if="isTransitionPage" name="slide" mode="out-in">
                <component :is="Component" />
            </transition>
            <component v-else :is="Component" />
        </router-view>
        <DialogWarning :dialog="showDialog" @update:dialog="setShowDialog" />
        <v-snackbar v-model="showSnackbar" :timeout="10000">
            {{ snackbarMessage }}
        </v-snackbar>
    </v-app>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { mapState, mapMutations, mapActions } from 'vuex';
import DialogWarning from './components/connected-components/Dialog-Warning.vue';

export default {
    data() {
        return {
            isOnline: navigator.onLine, // Vérifie si l'utilisateur est initialement en ligne
            showSnackbar: false,
            snackbarMessage: "",
        };
    },
    components: {
        DialogWarning
    },
 async mounted() {
        await this.loadUserPhoto(); // Charger la photo utilisateur dès que l'application est montée
        // Écoute les événements réseau
        window.addEventListener("online", this.handleOnline);
        window.addEventListener("offline", this.handleOffline);
   (async () => {
  if (Capacitor.isNativePlatform()) {
    // configureStatusBar();
    // Display content under transparent status bar (Android only)
StatusBar.setOverlaysWebView({ overlay: false });
StatusBar.setBackgroundColor({ color: '#FFFFFF' });
//    await setStatusBarStyleLight();
StatusBar.setStyle({ style: Style.Light });
StatusBar.show();

  } else {
    // console.log("Plateforme non native, initialisation GoogleAuth ignorée.");
  }
})();
     
    },
    beforeUnmount() {
           (async () => {
  if (Capacitor.isNativePlatform()) {
    // configureStatusBar();
    // Display content under transparent status bar (Android only)
StatusBar.setOverlaysWebView({ overlay: false });
StatusBar.setBackgroundColor({ color: '#FFFFFF' });
//    await setStatusBarStyleLight();
StatusBar.setStyle({ style: Style.Light });
StatusBar.show();

  } else {
    // console.log("Plateforme non native, initialisation GoogleAuth ignorée.");
  }
})();
        // Nettoie les écouteurs pour éviter les fuites mémoire
        window.removeEventListener("online", this.handleOnline);
        window.removeEventListener("offline", this.handleOffline);
    },
    computed: {
        isTransitionPage() {
            // Vérifiez si la route active est "bo0k-chapters" ou "book-chapters-specific"
            return this.$route.name === 'book-chapters' || this.$route.name === 'book-chapters-specific';
        },
        ...mapState(['showDialog'])
    },
    methods: {
//         async setStatusBarStyleLight() {
//   await StatusBar.setStyle({ style: Style.Light });
// },

// const hideStatusBar = async () => {
//   await StatusBar.hide();
// };

// async showStatusBar() {
//   await StatusBar.show();
        // },
        ...mapActions(['loadUserPhoto']), // Action pour charger la photo utilisateur

        ...mapMutations(['setShowDialog']),

        handleOnline() {
            this.isOnline = true;
            this.snackbarMessage = "Ou anliy!";
            this.showSnackbar = true;
        },
        handleOffline() {
            this.isOnline = false;
            this.snackbarMessage = "Koneksyon entènèt ou tonbe. Ou ap fonksyone san entènet. Sa rann aplikasyon an lan, men ou kapab kontinye li liv ou menm san entènet.";
            this.showSnackbar = true;
        },
    }
};
</script>

<style>
.bottom-safe-area {
    padding-bottom: env(safe-area-inset-bottom);
}
.slide-enter-active, .slide-leave-active {
    transition: transform 0.6s ease;
}
.slide-enter {
transform: translateX(100%);
}
.slide-leave-to {
    transform: translateX(-100%);
}
</style> -->
